<template>
  <div class="page">
    <CollectionDetailsModal ref="collectionDetailsModal" />
    <h3>Commission Rates</h3>
<!--    <div v-if="rates && rates.length > 0" class="commissions">-->
<!--      <div class="header">-->
<!--        <span class="range">Ticket / Product Price</span>-->
<!--        <span class="value">Commission</span>-->
<!--      </div>-->
<!--      <div v-for="(commission, index) in rates" class="commission" :key="index">-->
<!--        <div class="range">-->
<!--          {{ formatMoney(commission.min) }} - {{ formatMoney(commission.max) }}-->
<!--        </div>-->
<!--        <div class="value">-->
<!--          <FormulateInput-->
<!--            type="money"-->
<!--            disabled-->
<!--            ignored-->
<!--            small-->
<!--            placeholder="Enter Amount"-->
<!--            v-model="commission.value"-->
<!--          />-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="commissions" v-if="is_agent">
      Your commission rate is 10%.
    </div>
    <div class="commissions" v-else>
      Your commission rate is 30%.
    </div>
<!--    <div v-else class="commissions">-->
<!--      <Loader />-->
<!--    </div>-->
    <DataTable
      title="Collections"
      endpoint="/promoters/collections/:event"
      :args="{ event: url }"
      ref="table"
      :headers="headers"
      :custom-columns="['options']"
      :showSearch="false"
    >
      <template v-slot:[`item.options`]="{ item, index }">
        <div style="display: flex; gap: 4px">
          <TinyButton
            v-if="!item.confirmed"
            primary
            @click="onConfirmCollection(item, index)"
          >
            Confirm Collection
          </TinyButton>
          <TinyButton v-else secondary>
            Collection Confirmed
          </TinyButton>
          <TinyButton @click="onDetails(item, index)">Details</TinyButton>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
import DataTable from "@/components/DataTable.vue";
import TinyButton from "@/components/TinyButton.vue";
import Loader from "@/components/Loader.vue";
import CollectionDetailsModal from "../../components/modals/CollectionDetailsModal.vue";
import dayjs from "dayjs";

export default {
  name: "commissions",
  components: {
    DataTable,
    Loader,
    TinyButton,
    CollectionDetailsModal
  },
  data() {
    return {
      headers: [
        { text: "Collection Date", value: "collection_date", format: "date" },
        {
          text: "Total Paid",
          value: "collected",
          sortable: false,
          format: "currency"
        },
        {
          text: "Remaining Owed",
          value: "owed",
          sortable: false,
          format: "currency"
        },
        { text: "Options", value: "options", sortable: false }
      ],
      rates: null,
      is_agent: false,
    };
  },
  computed: {
    url() {
      return this.$route.params.url;
    }
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format("ddd, MMM DD, YYYY");
    },
    onConfirmCollection(item, index) {
      console.log("confirm", item, index);
      this.$axios
        .post("/promoters/collections/confirm", { collection: item._id })
        .then(response => {
          const { data } = response.data;
          this.$refs.table.set(index, data.collection);
          this.$toast("Collection Confirmed!", { type: "success" });
        });
    },
    onDetails(item) {
      console.log("details", item);
      this.$refs.collectionDetailsModal.open(item);
    }
  },
  created() {
    this.$axios.get("/promoters/" + this.url).then(response => {
      const { data } = response.data;
      console.log(data.promoter);
      if(data.promoter.is_salesperson) {
        this.is_agent = true
      }
      this.rates = data.promoter.commission_rates.map(r => ({
        ...r,
        value: Number(r.value).toFixed(2)
      }));
    });
  }
};
</script>

<style lang="less" scoped>
.page {
  .commissions {
    width: calc(40% - 16px);
    background-color: var(--content-light);
    padding: 16px;
    border-radius: 8px;

    .header {
      display: flex;
      margin-bottom: 12px;
      text-transform: uppercase;
      font-weight: bold;

      .range {
        width: 60%;
      }

      .value {
        width: 40%;
      }
    }

    .commission {
      display: flex;

      .range {
        display: flex;
        width: 60%;
        align-items: center;
        margin-bottom: 4px;
      }

      .value {
        width: 40%;

        .formulate-input {
          margin-bottom: 4px;
        }
      }
    }

    @media screen and (max-width: 800px) {
      width: calc(100% - 32px) !important;
    }
  }
}
</style>