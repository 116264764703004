<template>
  <div>
    <tab-tote
      :tabs="navTabs"
      :value="navTabSelected"
      @click="onClickNavTab"
    />
    <!--    <h3>Total Commission</h3>-->
    <!--    <div v-if="promoter" class="row">-->
    <!--      <div class="col quarter">${{ commission }}</div>-->
    <!--      <div class="col quarter">{{ formatDate(event.start) }}</div>-->
    <!--      <div class="col quarter">-->
    <!--        {{ promoter.tickets_sold || 0 }} Tickets Sold-->
    <!--      </div>-->
    <!--      <div class="col quarter">-->
    <!--        {{ promoter.products_sold || 0 }} Products Sold-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div v-else class="row" style="justify-content: center">-->
    <!--      <Loader />-->
    <!--    </div>-->
    <template v-if="promoter">
      <template v-if="buyerOrPlannerPaysFees === 'buyerPaysFees'">
        <DataTable
          v-if="navTabSelected === 0"
          :key="0"
          title="Sales"
          endpoint="/planners/financials/:event/calculateTicketSales"
          :args="{ event: url }"
          :params="{ pv: promoter._id }"
          ref="table"
          :showStat="true"
          :headers="headers[1]"
        ></DataTable>
        <DataTable
          v-if="navTabSelected === 1"
          :key="1"
          title="Product Sales"
          endpoint="/planners/financials/:event/calculateProductSales"
          :args="{ event: url }"
          :params="{ pv: promoter._id }"
          ref="table"
          :headers="headers[2]"
        ></DataTable>
        <DataTable
          v-if="navTabSelected === 2"
          :key="2"
          title="Discounts"
          endpoint="/planners/financials/:event/calculateDiscountSales"
          :args="{ event: url }"
          :params="{ pv: promoter._id }"
          ref="table"
          :headers="headers[4]"
        ></DataTable>
      </template>
      <template v-if="buyerOrPlannerPaysFees === 'plannerPaysFees'">
        <DataTable
          v-if="navTabSelected === 0"
          :key="0"
          title="Sales"
          endpoint="/planners/financials/:event/calculateTicketSales"
          :args="{ event: url }"
          :params="{ pv: promoter._id }"
          ref="table"
          :headers="headers[1]"
          :showStat="true"
        ></DataTable>
        <DataTable
          v-if="navTabSelected === 1"
          :key="1"
          title="Product Sales"
          endpoint="/planners/financials/:event/calculateProductSales"
          :args="{ event: url }"
          :params="{ pv: promoter._id }"
          ref="table"
          :headers="headers[3]"
        ></DataTable>
        <DataTable
          v-if="navTabSelected === 2"
          :key="2"
          title="Discounts"
          endpoint="/planners/financials/:event/calculateDiscountSales"
          :args="{ event: url }"
          :params="{ pv: promoter._id }"
          ref="table"
          :headers="headers[5]"
        ></DataTable>
      </template>
    </template>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import DataTable from "@/components/DataTable.vue";
import TabTote from "@/components/TabTote.vue";
import dayjs from "dayjs";

export default {
  name: "transactions",
  components: {
    Loader,
    DataTable,
    TabTote
  },
  props: {
    event: Object
  },
  data() {
    return {
      promoter: null,
      headers: [
        // Ticket Sales BPFT
        [
          { text: "", value: "name", sortable: true },
          { text: "Sold", value: "sold", sortable: true },
          { text: "Refunded", value: "refunded_count", sortable: false },
          {
            text: "Ticket Price",
            value: "ticket_price",
            sortable: false,
            format: "currency"
          },
          { text: "+", value: "", sortable: false },
          {
            text: "Add Fee",
            value: "add_fee",
            sortable: false,
            format: "currency"
          },
          { text: "=", value: "", sortable: false },
          {
            text: "Sub Total",
            value: "sub_total",
            sortable: true,
            format: "currency"
          },
          { text: "+", value: "", sortable: false },
          {
            text: "Planner Tax",
            value: "planner_tax",
            sortable: true,
            format: "currency"
          },
          { text: "-", value: "", sortable: false },
          {
            text: "Commission",
            value: "pv_commission",
            sortable: true,
            format: "currency"
          },
          { text: "=", value: "", sortable: false },
          { text: "Planner Total", value: "total", format: "currency" }
        ],
        // Ticket Sales PPFT
        [
          { text: "", value: "name", sortable: true },
          { text: "Sold", value: "sold", sortable: true },
          { text: "Refunded", value: "refunded_count", sortable: false },
          {
            text: "Total Paid",
            value: "total_paid",
            sortable: false,
            format: "currency"
          },
          // { text: "+", value: "", sortable: false },
          // {
          //   text: "Add Fee",
          //   value: "add_fee",
          //   sortable: false,
          //   format: "currency"
          // },
          // { text: "=", value: "", sortable: false },
          // {
          //   text: "Sub Total",
          //   value: "sub_total",
          //   sortable: true,
          //   format: "currency"
          // },
          { text: "-", value: "", sortable: false },
          {
            text: "Tax",
            value: "planner_tax",
            sortable: true,
            format: "currency"
          },
          { text: "-", value: "", sortable: false },
          { text: "Fee", value: "ccfee", sortable: true, format: "currency" },
          // { text: "-", value: "", sortable: false },
          // {
          //   text: "TT Tax",
          //   value: "tt_taxes",
          //   sortable: true,
          //   format: "currency"
          // },
          //  { text: "=", value: "", sortable: false },
          {
            text: "Commission",
            value: "pv_commission",
            sortable: true,
            format: "currency"
          }
          // { text: "=", value: "", sortable: false },
          // { text: "Planner Total", value: "total", format: "currency" }
        ],
        // Product Sales BPFT
        [
          { text: "", value: "product_name", sortable: true },
          { text: "Sold", value: "sold", sortable: true },
          {
            text: "Product Price",
            value: "product_price",
            sortable: false,
            format: "currency"
          },
          { text: "=", value: "", sortable: false },
          {
            text: "Sub Total",
            value: "sub_total",
            sortable: true,
            format: "currency"
          },
          { text: "+", value: "", sortable: false },
          {
            text: "Planner Tax",
            value: "planner_tax",
            sortable: true,
            format: "currency"
          },
          { text: "-", value: "", sortable: false },
          {
            text: "Commission",
            value: "pv_commission",
            sortable: true,
            format: "currency"
          },
          { text: "=", value: "", sortable: false },
          { text: "Planner Total", value: "total", format: "currency" }
        ],
        // Product Sales PPFT
        [
          { text: "", value: "product_name", sortable: true },
          { text: "Sold", value: "sold", sortable: true },
          {
            text: "Product Price",
            value: "product_price",
            sortable: false,
            format: "currency"
          },
          { text: "=", value: "", sortable: false },
          {
            text: "Sub Total",
            value: "sub_total",
            sortable: true,
            format: "currency"
          },
          { text: "+", value: "", sortable: false },
          {
            text: "Planner Tax",
            value: "planner_tax",
            sortable: true,
            format: "currency"
          },
          { text: "-", value: "", sortable: false },
          { text: "T-Fee", value: "dfee", sortable: true, format: "currency" },
          { text: "-", value: "", sortable: false },
          {
            text: "TT Tax",
            value: "tt_taxes",
            sortable: true,
            format: "currency"
          },
          { text: "-", value: "", sortable: false },
          {
            text: "Commission",
            value: "pv_commission",
            sortable: true,
            format: "currency"
          },
          { text: "=", value: "", sortable: false },
          { text: "Planner Total", value: "total", format: "currency" }
        ],
        // Discounts BPFT
        [
          { text: "", value: "item_name", sortable: false },
          { text: "Sold", value: "sold", sortable: false },
          {
            text: "# of Tickets",
            value: "num_of_tickets",
            sortable: false
          },
          {
            text: "# of Products",
            value: "num_of_products",
            sortable: false
          },
          {
            text: "Sub Total",
            value: "sub_total",
            sortable: true,
            format: "currency"
          },
          { text: "-", value: "", sortable: false },
          {
            text: "Discount",
            value: "price",
            sortable: false,
            format: "currency"
          },
          { text: "+", value: "", sortable: false },
          {
            text: "Planner Tax",
            value: "planner_tax",
            sortable: true,
            format: "currency"
          },
          { text: "-", value: "", sortable: false },
          {
            text: "Commission",
            value: "pv_commission",
            sortable: true,
            format: "currency"
          },
          { text: "=", value: "", sortable: false },
          { text: "Planner Total", value: "total", format: "currency" }
        ],
        // Discounts PPFT
        [
          { text: "", value: "item_name", sortable: false },
          { text: "Sold", value: "sold", sortable: false },
          {
            text: "# of Tickets",
            value: "num_of_tickets",
            sortable: false
          },
          {
            text: "# of Products",
            value: "num_of_products",
            sortable: false
          },
          {
            text: "Sub Total",
            value: "sub_total",
            sortable: true,
            format: "currency"
          },
          { text: "-", value: "", sortable: false },
          {
            text: "Discount",
            value: "price",
            sortable: false,
            format: "currency"
          },
          { text: "+", value: "", sortable: false },
          {
            text: "Planner Tax",
            value: "planner_tax",
            sortable: true,
            format: "currency"
          },
          { text: "-", value: "", sortable: false },
          { text: "T-Fee", value: "t_fee", sortable: true, format: "currency" },
          { text: "-", value: "", sortable: false },
          {
            text: "TT Tax",
            value: "tt_taxes",
            sortable: true,
            format: "currency"
          },
          { text: "-", value: "", sortable: false },
          {
            text: "Commission",
            value: "pv_commission",
            sortable: true,
            format: "currency"
          },
          { text: "=", value: "", sortable: false },
          { text: "Planner Total", value: "total", format: "currency" }
        ]
      ],
      buyerOrPlannerPaysFees: "",
      navTabSelected: 0,
      //navTabs: ["Ticket Sales", "Product Sales", "Discounts"]
      navTabs: ["Sales"]
    };
  },
  computed: {
    url() {
      return this.$route.params.url;
    },
    commission() {
      const tickets = this.promoter.ticket_commission || 0;
      const products = this.promoter.product_commission || 0;

      return (tickets + products).toFixed(2);
    }
  },
  methods: {
    onClickNavTab(index) {
      this.navTabSelected = index;
    },
    formatDate(date) {
      return dayjs(+date.$date.$numberLong).format("ddd, MMM DD, YYYY");
    }
  },
  created() {
    this.$axios.get("/promoters/" + this.url).then(response => {
      console.log(response);
      const { data } = response.data;
      console.log(data);
      this.promoter = data.promoter;
      this.buyerOrPlannerPaysFees = data.event.buyerOrPlannerPaysFees;
    });
  }
};
</script>

<style lang="less" scoped>
.tabs {
  display: none;
}
</style>
