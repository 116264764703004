<template>
  <button
    type="button"
    class="tiny-button"
    :class="{ primary, secondary, green, red }"
    @click="$emit('click')"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: "tiny-button",
  props: {
    primary: Boolean,
    secondary: Boolean,
    green: Boolean,
    red: Boolean
  }
};
</script>

<style lang="less" scoped>
.tiny-button {
  background-color: var(--content-dark);
  border: 1px solid var(--border);
  border-radius: 2px;
  font-size: 0.75rem;
  padding: 2px 8px;

  &.primary {
    background-color: var(--primary);
  }

  &.secondary {
    background-color: var(--secondary);
  }

  &.green {
    background-color: var(--primary-green);
  }

  &.red {
    background-color: var(--error-red);
  }
}
</style>