<template>
  <TTDialog title="Collection Details" :active="active" v-on:close="close">
    <div v-if="collection" class="row">
      <div class="detail col half">
        <strong>Amount</strong>
        <span>{{ formatMoney(collection.amount) }}</span>
      </div>
      <div class="detail col half">
        <strong>Owed</strong>
        <span>{{ formatMoney(collection.owed) }}</span>
      </div>
    </div>
    <div v-if="collection" class="row">
      <div class="detail col half">
        <strong>Collected</strong>
        <span>{{ formatMoney(collection.collected) }}</span>
      </div>
      <div class="detail col half">
        <strong>Collection Date</strong>
        <span>
          {{
            formatDate(collection.collection_date, "ddd, MMM DD, YYYY, hh:mm A")
          }}
        </span>
      </div>
    </div>
    <div v-if="collection" class="row">
      <div class="detail col half">
        <strong>Paid By</strong>
        <span>{{ collection.paid_by }}</span>
      </div>
      <div class="detail col half">
        <strong>Source</strong>
        <span>{{ collection.source }}</span>
      </div>
    </div>
    <div v-if="collection && collection.notes" class="row">
      <div class="detail col full">
        <strong>Notes</strong>
        <span>{{ collection.notes }}</span>
      </div>
    </div>
  </TTDialog>
</template>

<script>
import TTDialog from "@/components/TTDialog.vue";
import dayjs from "dayjs";
import AdvancedFormat from "dayjs/plugin/advancedFormat";
import UTC from "dayjs/plugin/utc";
import Timezones from "dayjs/plugin/timezone";

dayjs.extend(AdvancedFormat);
dayjs.extend(UTC);
dayjs.extend(Timezones);

export default {
  name: "collection-details-modal",
  components: {
    TTDialog
  },
  data() {
    return {
      active: false,
      collection: null
    };
  },
  methods: {
    formatDate(date) {
      if (date.$date) {
        let newDate = date.$date.$numberLong;
        return dayjs(+newDate).format("MMM DD, YYYY, hh:mm A");
      } else {
        let newDate = date;
        return dayjs(newDate).format("MMM DD, YYYY");
      }
    },
    open(collection) {
      this.collection = collection;
      console.log("from modal", collection);

      setTimeout(() => {
        this.active = true;
      }, 250);
    },
    close() {
      this.active = false;
    }
  }
};
</script>

<style lang="less" scoped>
.detail {
  display: flex;
  flex-direction: column;
}
</style>